import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Articles from "../components/articles"
import Pagination from "../components/pagination"

const BlogIndex = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={data.site.siteMetadata?.title}/>
      <Articles posts={posts} />
      <Pagination data={pageContext} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
        skip: $skip,
        limit: $limit,
        filter: {frontmatter: {status: {eq: "publish"}}},
        sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        slug
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          category
          tag
        }
      }
    }
  }
`
